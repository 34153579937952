/* Ripple effect */
.btn { outline: none !important; }
.btn.shadow:hover { box-shadow: none !important; }

.btn > .ripple {
    width: 20px;
    height: 20px;
    position: absolute;
    background: #63a4ff;
    display: block;
    content: "";
    border-radius: 9999px;
    opacity: 1;
    animation: 0.9s ease 1 forwards ripple-effect;
}
  
@keyframes ripple-effect {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(10);
        opacity: 0.375;
    }
    100% {
        transform: scale(35);
        opacity: 0;
    }
}