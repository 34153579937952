:root {
  --white: #FFFFFF;
  --blue: #3361E5;
  --grey: #a3a3a4;
  --opale: #363636;
  --red: #ee383F;

  --red-light: #FEE1E1;
}

.c-white { color: var(--white) !important; }
.c-blue { color: var(--blue) !important; }
.c-grey { color: var(--grey) !important; }
.c-opale { color: var(--opale) !important; }
.c-red { color: var(--red) !important; }

.bg-white { background-color: var(--white) !important; }
.bg-blue { background-color: var(--blue) !important; }
.bg-grey { background-color: var(--grey) !important; }
.bg-opale { background-color: var(--opale) !important; }
.bg-red { background-color: var(--red-light) !important; }

.border-red { border: 1px solid var(--red) !important; }

.border-radius { border-radius: 5px !important; }
.border-radius-right { border-radius: 0 5px 5px 0 !important; }

.pointer { cursor: pointer !important; }

.full-page { width: 100vw; height: 100vh; }

.background {
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .background {
    background-size: cover;
  }
}
