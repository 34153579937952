@font-face {
    font-family: "Open Sans";
    src: url("./OpenSans/OpenSans-Bold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: "Open Sans";
    src: url("./OpenSans/OpenSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: "Open Sans";
    src: url("./OpenSans/OpenSans-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Avenir";
    src: url("./Avenir/AvenirNextCyr-Bold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: "Avenir";
    src: url("./Avenir/AvenirNextCyr-Medium.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "Avenir";
    src: url("./Avenir/AvenirNextCyr-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Helvetica Neue";
    src: url("./HelveticaNeue/HelveticaNeueBold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: "Helvetica Neue";
    src: url("./HelveticaNeue/HelveticaNeue-Medium.otf") format("otf");
    font-weight: 500;
}
@font-face {
    font-family: "Helvetica Neue";
    src: url("./HelveticaNeue/HelveticaNeue-Light.otf") format("otf");
    font-weight: 300;
}

@font-face {
    font-family: "Montserrat";
    src: url("./Montserrant/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}
@font-face {
    font-family: "Montserrat";
    src: url("./Montserrant/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: "Montserrat";
    src: url("./Montserrant/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: url("./Roboto/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
}
@font-face {
    font-family: "Roboto";
    src: url("./Roboto/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "Roboto";
    src: url("./Roboto/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
}

.font-family-openSans { font-family: "Open Sans" !important; }
.font-family-avenir { font-family: "Avenir" !important; }
.font-family-helveticaNeue { font-family: "Helvetica Neue" !important; }
.font-family-montserrat { font-family: "Montserrat" !important; }
.font-family-roboto { font-family: "Roboto" !important; }

.font-size-11 { font-size: 8px !important; }
.font-size-12 { font-size: 9px !important; }
.font-size-13 { font-size: 10px !important; }
.font-size-14 { font-size: 11px !important; }
.font-size-16 { font-size: 12px !important; }
.font-size-18 { font-size: 13px !important; }
.font-size-19 { font-size: 14px !important; }
.font-size-20 { font-size: 15px !important; }
.font-size-22 { font-size: 16px !important; }
.font-size-23 { font-size: 17px !important; }
.font-size-24 { font-size: 18px !important; }
.font-size-25 { font-size: 19px !important; }
.font-size-27 { font-size: 20px !important; }
.font-size-28 { font-size: 21px !important; }
.font-size-29 { font-size: 21px !important; }
.font-size-30 { font-size: 22px !important; }
.font-size-32 { font-size: 22px !important; }
.font-size-33 { font-size: 23px !important; }
.font-size-34 { font-size: 24px !important; }
.font-size-35 { font-size: 25px !important; }
.font-size-36 { font-size: 26px !important; }
.font-size-38 { font-size: 27px !important; }
.font-size-40 { font-size: 29px !important; }
.font-size-42 { font-size: 30px !important; }
.font-size-45 { font-size: 31px !important; }
.font-size-50 { font-size: 35px !important; }
.font-size-55 { font-size: 37px !important; }
.font-size-68 { font-size: 46px !important; }
.font-size-72 { font-size: 49px !important; }
.font-size-74 { font-size: 50px !important; }

.font-weight-light { font-weight: 300 !important; }
.font-weight-regular { font-weight: 400 !important; }
.font-weight-medium { font-weight: 500 !important; }
.font-weight-semibold { font-weight: 600 !important; }
.font-weight-bold { font-weight: 700 !important; }
.font-weight-extrabold { font-weight: 800 !important; }
.font-weight-black { font-weight: 900 !important; }